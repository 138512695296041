import React, {useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../filterEngine";
import "./backgroundValue.scss"

const BackgroundValue = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const setBackgroundLetter = (index: number, char: string) => {

    if(context.inputValue && index < context.inputValue.length){
      return context.inputValue[index]
    }
    return char;

  }

  return (
    <div className='input-background'>
      {context.backgroundValue && context.backgroundValue.split('').map((char: string, index: number) => {
        return (
          <span
            key={index}
            className={`input-background-letter ${
              index < context.inputValue.length ? 'letter-hidden' : 'letter-visible'
            }`}
          >
              {setBackgroundLetter(index, char)}
            </span>
        )
      })}
    </div>
  )

}

export default BackgroundValue
