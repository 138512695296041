import React, { FC, SVGProps } from 'react'

interface ValidationErrorProps extends SVGProps<SVGElement> {}

const ValidationError: FC<ValidationErrorProps> = (props) => {
  const { width = 22, height = 22, onClick, className } = props

  return (
    <svg
      onClick={onClick}
      className={className}
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_746_246)'>
        <path
          d='M10.084 13.7507H11.9173V15.584H10.084V13.7507ZM10.084 6.41732H11.9173V11.9173H10.084V6.41732ZM10.9915 1.83398C5.93148 1.83398 1.83398 5.94065 1.83398 11.0007C1.83398 16.0607 5.93148 20.1673 10.9915 20.1673C16.0607 20.1673 20.1673 16.0607 20.1673 11.0007C20.1673 5.94065 16.0607 1.83398 10.9915 1.83398ZM11.0007 18.334C6.94898 18.334 3.66732 15.0523 3.66732 11.0007C3.66732 6.94898 6.94898 3.66732 11.0007 3.66732C15.0523 3.66732 18.334 6.94898 18.334 11.0007C18.334 15.0523 15.0523 18.334 11.0007 18.334Z'
          fill='#FF3838'
        />
      </g>
      <defs>
        <clipPath id='clip0_746_246'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ValidationError
