import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react'
import { filterEngineHelper } from './filterEngineHelper/filterEngineHelper'
import FilterEngineWContext from "./filterEngineWContext/filterEngineWContext";

export type SelectProps = {
  header ?: string,
  list: string[],
  onSelect: (item ?: string) => void,
  disabledItems?: string[],
  position ?: 'top' | 'bottom',
  placeholder?: string,
  error?: string,
  dropdownArrow?: "left" | "middle" | "right",
  inputWidth ?: number,
  dropdownWidth?: number,
  selectedItem ?: string,
  showDropdown ?: boolean
} & ( noEditable | editable ) & (singleSelect | multiSelect)

export type multiSelect = {
  multiSelect : true,
  defaultValue : undefined
}

export type singleSelect = {
  multiSelect ?: false,
  defaultValue ?: string
}

export type noEditable = {
  editable ?: false
}

export type editable = {
  editable: true,
  onDelete : (item : string) => void,
  onEdit : (item : string, editedItem : string) => void
}

export type ItemWithStatus = {
  name: string,
  status: ItemStatusEnum
}

export enum ItemStatusEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export type FilterContextType = SelectProps & {
  startedList : ItemWithStatus[],
  dropdownList : ItemWithStatus[],
  setDropdownList : Dispatch<SetStateAction<ItemWithStatus[]>>,
  inputValue : string,
  setInputValue : Dispatch<SetStateAction<string>>,
  backgroundValue ?: string,
  setBackgroundValue : Dispatch<SetStateAction<string | undefined>>,
  dropdownOpen : boolean,
  setDropdownOpen : Dispatch<SetStateAction<boolean>>,
  filterEngineRef : React.RefObject<HTMLDivElement>,
  editableMode : boolean,
  editedItem ?: string,
  editedItemNewValue ?: string,
  setEditableMode : Dispatch<SetStateAction<boolean>>,
  setEditedItem : Dispatch<SetStateAction<string>>,
  setEditedItemNewValue : Dispatch<SetStateAction<string>>
}

export const FilterEngineContext = React.createContext({});

const FilterEngine: FC<SelectProps> = (props) => {

  const {list,disabledItems,selectedItem,showDropdown = true} = props;

  const [startedList, setStartedList] = useState<ItemWithStatus[]>([])

  const [inputValue, setInputValue] = useState(selectedItem)
  const [backgroundValue, setBackgroundValue] = useState<string | undefined>()

  const [dropdownList, setDropdownList] = useState<ItemWithStatus[]>([])
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [editableMode, setEditableMode] = useState(false);
  const [editedItem, setEditedItem] = useState<string>('');
  const [editedItemNewValue, setEditedItemNewValue] = useState<string>('')

  const filterEngineRef = useRef<HTMLDivElement>(null);


  useEffect(() => {

    if (!inputValue || inputValue.length === 0) {
      setDropdownList(startedList)
      setInputValue('')
      return
    }

    const { filteredList, backgroundValue } = filterEngineHelper.filterListByValue(startedList, inputValue);

    setDropdownList(filteredList)
    setBackgroundValue(backgroundValue)

  }, [inputValue,startedList])

  useEffect(() => {
    const startedList = filterEngineHelper.getListWithStatuses(list, disabledItems, selectedItem)
    setStartedList(startedList)
    setDropdownList(startedList)

    if(selectedItem){
      setInputValue(selectedItem)
    }

  },[list, disabledItems, selectedItem])

  const providerState = {
    ...props,
    showDropdown,
    startedList,
    inputValue,
    setInputValue,
    backgroundValue,
    setBackgroundValue,
    dropdownList,
    setDropdownList,
    dropdownOpen,
    setDropdownOpen,
    filterEngineRef,
    setEditableMode,
    setEditedItem,
    setEditedItemNewValue,
    editableMode,
    editedItem,
    editedItemNewValue
  }

  return (
    <FilterEngineContext.Provider value={providerState} >
      <FilterEngineWContext />
    </FilterEngineContext.Provider>
  )

}

export default FilterEngine
