import React, {useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../../filterEngine";
import classNames from "classnames";
import DropdownButton from "../../dropdownButton/dropdownButton";
import "./defaultValueFooter.scss"

const DefaultValueFooter = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const handleDefaultValueClick = () => {
    context.onSelect(context.defaultValue);
    context.setDropdownOpen(false)
  }

  if(!context.defaultValue){
    return <div></div>
  }

  return (
    <div
      className={classNames({
        'dropdown-footer' : true,
        'default-value' : true,
        'default-value-border-top' : context.dropdownList.length > 0
      })}
    >
      <DropdownButton
        disabled={false}
        onClick={handleDefaultValueClick}
        name={context.defaultValue}
        buttonEditable={false}
      />
    </div>
  )
}

export default DefaultValueFooter
