import React, {FC, SVGProps} from "react";

interface AddCircleProps extends SVGProps<SVGElement> {}

const AddCircle : FC<AddCircleProps> = (props) => {
  const {width = 21, height = 21, onClick, className, stroke = "#10C998", fill = "white", strokeWidth = "0.3"} = props
  return (
    <svg width={width} height={height} onClick={onClick} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4167 14.5665H14.5667V14.4165V8.23317H17.4334V14.4165V14.5665H17.5834H23.7668V17.4332H17.5834H17.4334V17.5832V23.7665H14.5667V17.5832V17.4332H14.4167H8.23342V14.5665H14.4167ZM0.316748 15.9998C0.316748 7.34268 7.34293 0.316504 16.0001 0.316504C24.6572 0.316504 31.6834 7.34268 31.6834 15.9998C31.6834 24.657 24.6572 31.6832 16.0001 31.6832C7.34293 31.6832 0.316748 24.657 0.316748 15.9998ZM3.18341 15.9998C3.18341 23.0652 8.93474 28.8165 16.0001 28.8165C23.0654 28.8165 28.8168 23.0652 28.8168 15.9998C28.8168 8.9345 23.0654 3.18317 16.0001 3.18317C8.93474 3.18317 3.18341 8.9345 3.18341 15.9998Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default AddCircle
