import React, {FC, SVGProps} from "react";

interface DeleteIconProps extends SVGProps<SVGElement> {}

const DeleteIcon : FC<DeleteIconProps> = (props) => {
  const {width = 14, height = 14, onClick, className} = props
  return (
    <svg width={width} height={height} onClick={onClick} className={className} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1079_2067)">
        <path
          d="M8.93138 5.07227C8.75029 5.07227 8.60352 5.21904 8.60352 5.40013V11.5969C8.60352 11.7778 8.75029 11.9247 8.93138 11.9247C9.11248 11.9247 9.25925 11.7778 9.25925 11.5969V5.40013C9.25925 5.21904 9.11248 5.07227 8.93138 5.07227Z"
          fill="#878787"
        />
        <path
          d="M5.06224 5.07227C4.88115 5.07227 4.73438 5.21904 4.73438 5.40013V11.5969C4.73438 11.7778 4.88115 11.9247 5.06224 11.9247C5.24334 11.9247 5.39011 11.7778 5.39011 11.5969V5.40013C5.39011 5.21904 5.24334 5.07227 5.06224 5.07227Z"
          fill="#878787"
        />
        <path
          d="M2.24272 4.16793V12.2459C2.24272 12.7234 2.4178 13.1717 2.72364 13.4935C3.02807 13.8161 3.45173 13.9992 3.89512 14H10.0985C10.542 13.9992 10.9657 13.8161 11.27 13.4935C11.5758 13.1717 11.7509 12.7234 11.7509 12.2459V4.16793C12.3589 4.00656 12.7528 3.41922 12.6715 2.79537C12.59 2.17166 12.0586 1.70508 11.4296 1.70496H9.7509V1.29512C9.75282 0.950476 9.61655 0.619534 9.37257 0.376067C9.12859 0.132727 8.79713 -0.00277453 8.45249 4.30791e-05H5.54112C5.19648 -0.00277453 4.86502 0.132727 4.62104 0.376067C4.37706 0.619534 4.24079 0.950476 4.24272 1.29512V1.70496H2.56406C1.93496 1.70508 1.40358 2.17166 1.32213 2.79537C1.2408 3.41922 1.63476 4.00656 2.24272 4.16793ZM10.0985 13.3443H3.89512C3.33455 13.3443 2.89846 12.8627 2.89846 12.2459V4.19675H11.0952V12.2459C11.0952 12.8627 10.6591 13.3443 10.0985 13.3443ZM4.89845 1.29512C4.89627 1.1244 4.96338 0.960081 5.08454 0.839564C5.20557 0.719047 5.37027 0.652833 5.54112 0.655779H8.45249C8.62334 0.652833 8.78804 0.719047 8.90907 0.839564C9.03023 0.959953 9.09734 1.1244 9.09516 1.29512V1.70496H4.89845V1.29512ZM2.56406 2.36069H11.4296C11.7555 2.36069 12.0197 2.62491 12.0197 2.95085C12.0197 3.2768 11.7555 3.54102 11.4296 3.54102H2.56406C2.23811 3.54102 1.9739 3.2768 1.9739 2.95085C1.9739 2.62491 2.23811 2.36069 2.56406 2.36069Z"
          fill="#878787"
        />
        <path
          d="M6.99681 5.07227C6.81572 5.07227 6.66895 5.21904 6.66895 5.40013V11.5969C6.66895 11.7778 6.81572 11.9247 6.99681 11.9247C7.1779 11.9247 7.32467 11.7778 7.32467 11.5969V5.40013C7.32467 5.21904 7.1779 5.07227 6.99681 5.07227Z"
          fill="#878787"
        />
      </g>
      <defs>
        <clipPath id="clip0_1079_2067">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeleteIcon
