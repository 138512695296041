import {FilterContextType, FilterEngineContext} from "../filterEngine";
import React, {useContext, useEffect} from "react";
import InputFiltered from "../inputFiltered/inputFiltered";
import DropdownFiltered from "../dropdownFiltered/dropdownFiltered";
import "./filterEngineWContext.scss"
import {InputErrorMessage} from "../../inputErrorMessage/inputErrorMessage";

const FilterEngineWContext = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const handleClickOutside = (e : MouseEvent ) => {
    if(context.filterEngineRef.current && e.target instanceof HTMLElement ){
      if(!context.filterEngineRef.current.contains(e.target)){
        context.setDropdownOpen(false);
        context.setEditableMode(false)
        context.setEditedItem('')
        context.setEditedItemNewValue('')
      }
    }
  }

  useEffect(() => {
    if(context.dropdownOpen){
      document.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }

  },[context.dropdownOpen]);

  return (
    <div className='filter-engine-container'  >
      <h1 className='filter-engine-header'>{context.header}</h1>

      <InputFiltered />

      {context.showDropdown && (
        <div className={'filter-engine-dropdown'} ref={context.filterEngineRef}>
          <DropdownFiltered/>
        </div>
      )}

      <InputErrorMessage errorMessage={context.error} width={context.inputWidth} />

    </div>
  )

}

export default FilterEngineWContext;
