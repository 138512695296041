import React, {FC, SVGProps} from "react";

interface ClearProps extends SVGProps<SVGElement> {}

const Clear : FC<ClearProps> = (props) => {
  const {width = 15, height = 14, onClick, className} = props
  return (
    <svg width={width} height={height} onClick={onClick} className={className} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.646447" y1="13.0918" x2="13.0901" y2="0.648108" stroke="#919191"/>
      <line x1="1.35355" y1="0.646447" x2="13.7972" y2="13.0901" stroke="#919191"/>
    </svg>
  )
}

export default Clear
