
import React, {FC, SVGProps} from "react";

interface SearchProps extends SVGProps<SVGElement> {}

const Search : FC<SearchProps> = (props) => {
  const {width = 21, height = 21, onClick, className} = props

  return (
    <svg width={width} height={height} viewBox="0 0 21 21" onClick={onClick} className={className}  fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0833 13.3333H14.1617L13.835 13.0183C14.9783 11.6883 15.6667 9.96167 15.6667 8.08333C15.6667 3.895 12.2717 0.5 8.08333 0.5C3.895 0.5 0.5 3.895 0.5 8.08333C0.5 12.2717 3.895 15.6667 8.08333 15.6667C9.96167 15.6667 11.6883 14.9783 13.0183 13.835L13.3333 14.1617V15.0833L19.1667 20.905L20.905 19.1667L15.0833 13.3333ZM8.08333 13.3333C5.17833 13.3333 2.83333 10.9883 2.83333 8.08333C2.83333 5.17833 5.17833 2.83333 8.08333 2.83333C10.9883 2.83333 13.3333 5.17833 13.3333 8.08333C13.3333 10.9883 10.9883 13.3333 8.08333 13.3333Z" fill="#808080"/>
    </svg>
  )
}

export default Search
