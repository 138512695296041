import {ItemStatusEnum, ItemWithStatus} from "../filterEngine";

export namespace filterEngineHelper {

  export const filterListByValue = (list : ItemWithStatus[], value : string) : {filteredList : ItemWithStatus[], backgroundValue : string | undefined} => {

    let filteredList : ItemWithStatus[];
    let backgroundValue : string | undefined;

    filteredList = list.filter((item : ItemWithStatus) => {

      value = value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");

      const x = item.name.toLowerCase().trim()
      const y = x.split(" ").reverse().join(" ")
      const r = RegExp(`${value.toLowerCase().trim()}`, 'igm');
      return x.match(r) || y.match(r)
    });


    backgroundValue = filteredList.filter( (listItem : ItemWithStatus) => {

      const item = listItem.name.toLowerCase().trim();
      const search = value.toLowerCase().trim();

      return item.startsWith(search)

    })[0]?.name

    return {filteredList, backgroundValue : backgroundValue || undefined };

  }

  export const getListWithStatuses = (list : string[], disabledItems ?: string[], selectedItem ?: string) : ItemWithStatus[] => {

    return list.map((item : string) => {
      if(disabledItems && disabledItems.includes(item) || item === selectedItem ){
        return {
          name : item,
          status : ItemStatusEnum.DISABLED
        }
      }

      return {
        name : item,
        status : ItemStatusEnum.ACTIVE
      }
    })

  }

}
