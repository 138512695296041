import React, {FC, SVGProps} from "react";

interface EditIconProps extends SVGProps<SVGElement> {}

const EditIcon : FC<EditIconProps> = (props) => {
  const {width = 14, height = 14, onClick, className, fill = "#10C998", strokeWidth = '0.2' } = props
  return (
    <svg width={width} height={height} onClick={onClick} className={className} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.68 2.06418L11.936 0.320311H11.936C11.5088 -0.106784 10.8139 -0.106757 10.3868 0.320311L1.6198 9.08707C1.59057 9.11633 1.56831 9.15185 1.55477 9.19095L0.0150737 13.6371C-0.0192141 13.736 0.00605062 13.8459 0.0800949 13.9199C0.154303 13.9943 0.265042 14.0191 0.362929 13.9849L4.80912 12.4452C4.84822 12.4316 4.88373 12.4094 4.91299 12.3802L13.68 3.61342C14.1032 3.20777 14.1032 2.46987 13.68 2.06418ZM11.3598 5.16018L9.47003 3.27041C9.46994 3.27033 9.46983 3.27022 9.46975 3.27013L8.84002 2.6404L9.42004 2.06041L11.9398 4.58016L11.3598 5.16018ZM4.90435 11.6154L4.78303 10.8636L10.5364 5.11022L10.9731 5.54689L4.90435 11.6154ZM8.45331 3.02711L8.88984 3.46364L3.13647 9.21698L2.3846 9.09566L8.45331 3.02711ZM9.27655 3.85032L10.1497 4.72351L4.38093 10.4923L3.53223 10.4678L3.50771 9.61916L9.27655 3.85032ZM1.99636 9.58695L2.96419 9.74311L2.99306 10.7416C2.99724 10.8864 3.11367 11.0028 3.25848 11.007L4.25695 11.0358L4.41311 12.0036L2.24988 12.7528C2.15514 12.5302 2.01676 12.3248 1.84606 12.154C1.6753 11.9833 1.46979 11.8449 1.24725 11.7502L1.99636 9.58695ZM1.06766 12.2686C1.35927 12.4014 1.59861 12.6407 1.73138 12.9323L0.716061 13.2839L1.06766 12.2686ZM13.2933 3.22674L12.3265 4.19347L9.80672 1.67373L10.7735 0.70702C10.9874 0.49309 11.3355 0.493172 11.5493 0.70702L13.2933 2.45089C13.5044 2.65301 13.5044 3.02462 13.2933 3.22674Z"
        fill={fill}
        stroke={'black'}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default EditIcon
