import React, { FC } from 'react'
import ValidationError from '../assets/icons/validationError';
import './inputErrorMessage.scss'

interface InputErrorMessageProps{
  errorMessage?: string;
  width ?: number;
}

export const InputErrorMessage : FC<InputErrorMessageProps> = (props) => {
    const {errorMessage,width} = props;
    return (
        <div className="inputErrorMessage_wrapper" style={{width : width ? width + "px" : "auto"}} >
          <div className={"error-icon"} >
            {errorMessage ? <ValidationError /> : null}
          </div>
            <span className="errorMessage">{errorMessage}</span>
        </div>
    )
}
