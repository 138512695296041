import BackdropLayout from '../backdropLayout/backdropLayout'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import './modal.scss'
import Clear from '../assets/icons/clear'
import classNames from 'classnames'

export interface ModalProps {
  onClose: () => void
  backdropClass?: string
  modalClass?: string
  isOpen: boolean
  children: React.ReactNode
}

const Modal: FC<ModalProps> = (props) => {
  const { onClose, backdropClass, modalClass, isOpen, children } = props

  if (!isOpen || typeof window !== 'object') {
    return null
  }

  return ReactDOM.createPortal(
    <BackdropLayout
      onClick={onClose}
      className={
        classNames({
          'modal-backdrop': true
        }) + `${backdropClass ? ' ' + backdropClass : ''}`
      }
    >
      <div
        className={
          classNames({
            modal: true
          }) + `${modalClass ? ' ' + modalClass : ''}`
        }
      >
        <button className='modal-close-button' type='button' onClick={onClose}>
          <Clear width={20} height={20} />
        </button>
        {children}
      </div>
    </BackdropLayout>,
    document.body
  )
}

export default Modal
