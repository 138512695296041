import classNames from "classnames";
import {FilterContextType, FilterEngineContext, ItemStatusEnum, ItemWithStatus} from "../../filterEngine";
import DropdownButton from "../dropdownButton/dropdownButton";
import React, {useContext} from "react";
import "./dropdownList.scss"

const DropdownList = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const showNoResultMess = () => {
    return context.dropdownList.length === 0
  }

  const handleClick = (item ?: string) => {

    context.onSelect(item);

    if(!context.multiSelect){
      context.setDropdownOpen(false)
      return;
    }

    context.setInputValue('')

  }

  return(
    <div>

      <div
        className={classNames({
          'filtered-dropdown' : true,
          'filtered-dropdown-empty' : context.dropdownList.length === 0,
          'filtered-dropdown-without-footer' : (context.inputValue && context.inputValue.length < 3 && !context.editableMode) || context.selectedItem
        })}
      >

        {context.dropdownList.map((item: ItemWithStatus, index: number) => (
          <DropdownButton
            key={index}
            disabled={ (item.status === ItemStatusEnum.DISABLED) || (context.editableMode && context.editedItem !== item.name) }
            onClick={handleClick}
            isEdited={item.name === context.editedItem && context.editableMode}
            name={item.name}
            buttonEditable={true}
          />
        ))}

      </div>

      { showNoResultMess() && (
        <p className={'no-result'} >
          Brak Wyników
        </p>
      )}

    </div>
  )

}

export default DropdownList
