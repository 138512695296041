import classNames from "classnames";
import AddCircle from "../../../../assets/icons/addCircle";
import React, {useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../../filterEngine";
import "./createItemFooter.scss"

const CreateItemFooter = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const handleCreateNewItem = () => {
    context.onSelect(context.inputValue);

    if(context.multiSelect){
      context.setInputValue('')
    }

    if(!context.multiSelect){
      context.setDropdownOpen(false)
    }

  }

  return (
    <div
      className={classNames({
        'dropdown-footer' : true,
        'create-item' : true
      })}
    >
      <div className={'create-item-text'} >
        <p>Utwórz nową pozycję: </p>
        <p className={'create-item-value'} >"{context.inputValue}"</p>
      </div>

      <button
        className={'create-item-button'}
        type={'button'}
        onClick={handleCreateNewItem}
      >
        <AddCircle fill={"#10C998"} strokeWidth={"0.1"} />
        <p>Utwórz</p>
      </button>

    </div>
  )
}

export default CreateItemFooter
