import React, { FC } from 'react'
import classNames from 'classnames'
import './button.scss'

export interface ButtonProps {
  text?: string
  onClick?: () => void
  icon?: React.ReactNode
  type?: 'submit' | 'button' | 'reset'
  color?: 'normal' | 'warning' | 'success'
  withBackground?: boolean
  withBorder?: boolean
  width?: number
  height?: number
  reverse?: boolean
  className?: string
}

const Button: FC<ButtonProps> = (props) => {
  const {
    text,
    onClick,
    icon,
    type = 'button',
    color = 'success',
    withBackground = true,
    withBorder = true,
    width,
    height = "45px",
    reverse = false,
    className
  } = props

  return (
    <button
      type={type}
      onClick={onClick}
      className={
        classNames({
          button: true,
          reverse: reverse,
          warning: color === 'warning',
          success: color === 'success',
          normal: color === 'normal',
          background_warning: withBackground && color === 'warning',
          background_success: withBackground && color === 'success',
          border_warning: !withBackground && withBorder && color === 'warning',
          border_success: !withBackground && withBorder && color === 'success',
          circle: !text && icon
        }) + `${className ? ' ' + className : ''}`
      }
      style={{
        width: width || 'auto',
        height: !text && icon ? 'auto' : height
      }}
    >
      {icon}

      {text && <p>{text}</p>}
    </button>
  )
}

export default Button
