import DeleteIcon from "../../../../assets/icons/deleteIcon";
import EditIcon from "../../../../assets/icons/edit";
import React, {FC, useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../../filterEngine";
import "./buttonIcons.scss"

interface ButtonIconsProps {
  name : string,
  disabled: boolean,
  isEdited ?: boolean,
  buttonEditable : boolean
}

const ButtonIcons : FC<ButtonIconsProps> = (props) => {

  const context = useContext(FilterEngineContext) as FilterContextType;
  const {name, disabled,isEdited,buttonEditable} = props;

  const handleDelete = () => {
    if (context.editable && context.editedItem && buttonEditable) {
      context.onDelete(context.editedItem)
      context.setEditedItem('')
      context.setEditableMode(false)
    }
  }

  const handleEditClick = () => {

    if(context.editableMode && buttonEditable){
      context.setEditedItem('')
      context.setEditableMode(false)
      return
    }

    context.setEditedItem && context.setEditedItem(name)

  }

  return (
    <div className={'button-icons'} >
      { isEdited && buttonEditable && (
        <button className={'delete-button'} type={'button'} onClick={handleDelete} >
          <DeleteIcon />
        </button>
      )}

      { !disabled && buttonEditable && context.editable && (
        <button className={'edit-button'} type={'button'} onClick={handleEditClick} >
          {isEdited ? (
            <EditIcon strokeWidth={0} />
          ) : (
            <EditIcon fill={'white'} />
          )}
        </button>
      )}
    </div>
  )
}

export default ButtonIcons
