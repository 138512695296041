import React, {ChangeEvent, useContext} from 'react'
import './inputFiltered.scss'
import {FilterContextType, FilterEngineContext, ItemStatusEnum} from '../filterEngine'
import classNames from "classnames";
import BackgroundValue from "./backgroundValue/backgroundValue";
import InputButtons from "./inputButtons/inputButtons";

const InputFiltered = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const listIncludesValue = (e: ChangeEvent<HTMLInputElement>) => {
    return (
      context.backgroundValue
      && e.target.value.toLowerCase().trim() === context.backgroundValue.toLowerCase().trim()
      && context.dropdownList[0].status === ItemStatusEnum.ACTIVE
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value.trimStart();

    if (listIncludesValue(e)) {
      context.onSelect(context.backgroundValue)

      if(!context.multiSelect){
        context.setDropdownOpen(false)
        return;
      }

      context.setInputValue('')
      return
    }

    if(context.selectedItem){
      context.onSelect('')
    }

    if(!context.dropdownOpen){
      context.setDropdownOpen(true)
    }

    context.setInputValue(value)

  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

    if(!context.dropdownOpen){
      context.setDropdownOpen(true)
    }

    if (e.key === 'Enter' && context.inputValue && context.backgroundValue) {

      context.onSelect(context.backgroundValue)

      if(!context.multiSelect){
        context.setDropdownOpen(false)
        return;
      }

      context.setInputValue('')

    }

  }

  const handleClick = () => context.setDropdownOpen(!context.dropdownOpen)
  const handleFocus = () => context.setDropdownOpen(true)

  return (
    <div
      className={'input-container'}
      style={{
        width : context.inputWidth ? context.inputWidth + "px" : "100%"
      }}
    >
      <input
        type='text'
        value={context.inputValue || ""}
        onChange={handleChange}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        readOnly={context.editableMode}
        placeholder={context.placeholder || 'Wybierz lub zacznij pisać'}
        className={classNames({
          'input' : true,
          'error' : context.error,
          'input_top' : context.position === 'top',
          'input_bottom' : context.position === "bottom"
        })}
      />

      <InputButtons />

      {context.inputValue && context.backgroundValue && (
        <BackgroundValue />
      )}

    </div>
  )
}

export default InputFiltered
