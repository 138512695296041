import React, {ReactElement, FC, useRef} from 'react'
import "./backdropLayout.scss"

interface BackdropProps{
  onClick: ()=> void;
  children : React.ReactNode
  className?: string
}

const BackdropLayout: FC<BackdropProps> = (props) : ReactElement => {

  const {onClick,children,className} = props;
  const backdropLayoutRef = useRef<HTMLDivElement>(null);

  const handleClick = (e : React.MouseEvent) => {
    if(backdropLayoutRef.current && e.target === backdropLayoutRef.current){
      onClick()
    }
  }

  return (
    <div
      className={['backdrop-layout',className ? className : ""].join(" ")}
      onClick={handleClick}
      ref={backdropLayoutRef}
    >
        {children}
    </div>
  )
}
export default BackdropLayout
