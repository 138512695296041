import Clear from "../../../assets/icons/clear";
import Search from "../../../assets/icons/search";
import React, {useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../filterEngine";
import "./inputButtons.scss"

const InputButtons = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const clearInput = () => {
    context.onSelect('')
    context.setInputValue('')
  }

  return (
    <div className={'input-buttons'} >
      {context.inputValue && (
        <Clear
          className='input-clear'
          height={22}
          width={22}
          onClick={clearInput}
        />
      )}

      <Search className='input-search-icon' width={22} height={22} />
    </div>
  )
}

export default InputButtons
