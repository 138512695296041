import React, {FC, useContext} from "react";
import "./dropdownButton.scss"
import classNames from "classnames";
import {FilterContextType, FilterEngineContext} from "../../filterEngine";
import ButtonIcons from "./buttonIcons/buttonIcons";

interface DropdownButtonProps {
  name : string,
  disabled: boolean,
  onClick : (item : string) => void,
  isEdited ?: boolean,
  buttonEditable : boolean
}

const DropdownButton : FC<DropdownButtonProps> = (props) => {

  const context = useContext(FilterEngineContext) as FilterContextType;
  const {name, disabled, onClick,isEdited,buttonEditable} = props;

  const handleClick = () => onClick(name)

  const handleChange = (e : React.ChangeEvent) => {
    if(e.target){
      const value = (e.target as HTMLInputElement).value;
      context.setEditedItemNewValue(value)
    }
  }

  const handleKeyDown = (e : React.KeyboardEvent) => {
    if(e.key === "Enter" && context.editedItem && context.editedItemNewValue && context.editable && buttonEditable){
      context.onEdit(context.editedItem, context.editedItemNewValue)
      context.setEditedItem('')
      context.setEditableMode(false)
    }
  }

  const buttonName = () => {

    if(context.inputValue){
      const indexStart = name.toLowerCase().trim().indexOf(context.inputValue.toLowerCase().trim())

      if(indexStart >= 0){

        const value = [name.slice(0,indexStart),name.slice(indexStart+ context.inputValue.length)];
        const modifiedValue = name.slice(indexStart, indexStart + context.inputValue.length);

        return (
          <p>
            {value[0]}
            <span className={'modified-value'} >{modifiedValue}</span>
            {value[1]}
          </p>
        );
      }

      return <p>{name}</p>;
    }

    return <p>{name}</p>;
  }

  return (
    <div
      className={classNames({
        'dropdown-button-wrapper' : true,
        'dropdown-button-hover' : !(isEdited && context.editable)
      })}
    >

      {isEdited && context.editable ? (
        <input value={context.editedItemNewValue} className={'dropdown-input'} onChange={handleChange} onKeyDown={handleKeyDown} />
      ) : (
        <button
          type={'button'}
          className={'dropdown-button'}
          disabled={disabled}
          onClick={handleClick}
        >
          {buttonName()}
        </button>
      )}

      <ButtonIcons
        name={name}
        disabled={disabled}
        isEdited={isEdited}
        buttonEditable={buttonEditable}
      />

    </div>

  )
}

export default DropdownButton
