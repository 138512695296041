import React, {FC, useContext, useEffect, useState} from 'react'
import NavBarContainer from './navbarContainer';

export interface NavbarProviderProps {
    lastName: string,
    firstName: string,
    module: string,
    currentPage : string
    basePath : string,
    router : (href : string) => void,
    HR ? : boolean,
    TR ? : boolean
}

type NavbarValues = {
    basePath : string,
    HR ? : boolean,
    TR ? : boolean
    router : (href : string) => void,
    isSideBarOpen: boolean
    toggleIsUserPopUpOpen: () => void
    isUserPopUpOpen: boolean
    toggleIsSideBarOpen: () => void
    windowSize: {
        width: number;
        height: number;
    },
    user: {
        lastName: string,
        firstName: string,
        initials : string,
        fullName : string
    },
    module: string,
    currentPage : string
}

const defaultContextValues: NavbarValues = {
    basePath : "",
    router : () => {},
    isSideBarOpen: false,
    HR : false,
    TR : false,
    toggleIsUserPopUpOpen: () => { },
    isUserPopUpOpen: false,
    toggleIsSideBarOpen: () => { },
    windowSize: {
        width: 0,
        height: 0
    },
    user: {
        lastName: "",
        firstName: "",
        initials : "",
        fullName : ""
    },
    module: "",
    currentPage : ""
}

const ThemeContext = React.createContext<NavbarValues>(defaultContextValues);

export function useNavbarContext() {
    return useContext(ThemeContext)
}

const DevapoNavBar : FC<NavbarProviderProps> = (props) => {
    const { lastName, firstName, module, currentPage, basePath, router, HR, TR } = props;

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
    const [isUserPopUpOpen, setIsUserPopUpOpen] = useState<boolean>(false);

    const toggleIsSideBarOpen = () => setIsSideBarOpen(currentVal => !currentVal)
    const toggleIsUserPopUpOpen = () => setIsUserPopUpOpen(currentVal => !currentVal);


    const navbarContextValue = {
        HR,
        TR,
        router,
        basePath,
        isSideBarOpen,
        toggleIsUserPopUpOpen,
        isUserPopUpOpen,
        toggleIsSideBarOpen,
        windowSize,
        user: {
            lastName: lastName.trim(),
            firstName: firstName.trim(),
            fullName : [lastName.trim(),firstName.trim()].join(" "),
            initials : [lastName[0].toUpperCase(),firstName[0].toUpperCase()].join("").trim()
        },
        module,
        currentPage
    }

    useEffect(() => {
        const updateWindowSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        };
        updateWindowSize();

        window.addEventListener("resize", updateWindowSize);

        return () => window.removeEventListener("resize", updateWindowSize)

    }, []);

    return (
        <ThemeContext.Provider value={navbarContextValue}>
            <NavBarContainer />
        </ThemeContext.Provider>
    )
}


export default DevapoNavBar
