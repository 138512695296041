import React, {useContext, useEffect} from 'react'
import './dropdownFiltered.scss'
import {FilterContextType, FilterEngineContext} from '../filterEngine'
import classNames from "classnames";
import DropdownList from "./dropdownList/dropdownList";
import DropdownFooter from "./dropdownFooter/dropdownFooter";

const DropdownFiltered = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  useEffect(() => {
    if(context.editedItem){
      context.setEditableMode(true);
      context.setEditedItemNewValue(context.editedItem);
      return;
    }

    context.setEditableMode(false);
    context.setEditedItemNewValue('')
  },[context.editedItem])

  const handleCloseDropdown = () => {
    context.setDropdownOpen(false);
  }

  return (
    <div
      className={classNames({
        'dropdown-container' : true,
        'dropdown-bottom' : context.position === 'bottom',
        'dropdown-top' : context.position === 'top',
        'dropdown-open' : context.dropdownOpen
      })}
      style={{
        width : context.dropdownWidth ? context.dropdownWidth + "px" : "auto",
      }}
    >
      <div
        className={classNames({
          'dropdown' : true,
        })}
      >

        <DropdownList />

        <DropdownFooter />

        <button className={'hidden-button'} type={'button'} onFocus={handleCloseDropdown} />

      </div>

    </div>
  )
}

export default DropdownFiltered
