import {useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../filterEngine";
import React from "react";
import EditModeFooter from "./editModeFooter/editModeFooter";
import DefaultValueFooter from "./defaultValueFooter/defaultValueFooter";
import CreateItemFooter from "./createItemFooter/createItemFooter";

const DropdownFooter = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const canCreateNewItem = () => {
    return context.editable &&
      ( context.inputValue && context.inputValue.length >= 3 )
      && context.inputValue !== context.selectedItem
      && (
        !context.backgroundValue
        || (
          context.backgroundValue &&
          context.backgroundValue.toLowerCase().trim() !== context.inputValue.toLowerCase().trim()
        )
      )
  }

  const showDefaultValueOption = () => {
    return  context.defaultValue && !context.multiSelect && !context.inputValue
  }

  if(context.editableMode && context.editable && context.onEdit){
    return (
      <EditModeFooter />
    )
  }

  if( showDefaultValueOption() ){
    return (
      <DefaultValueFooter />
    )
  }

  if(canCreateNewItem()){
    return (
      <CreateItemFooter />
    )
  }

  return <div></div>

}

export default DropdownFooter
