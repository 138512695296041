import classNames from "classnames";
import React, {useContext} from "react";
import {FilterContextType, FilterEngineContext} from "../../../filterEngine";
import "./editModeFooter.scss"

const EditModeFooter = () => {

  const context = useContext(FilterEngineContext) as FilterContextType;

  const handleCancelEditMode = () => {
    context.setEditedItem('')
  }

  const canEditItem = () : boolean => {
    return (context.editable
      && context.onEdit
      && editedItemValidation() ) as boolean;
  }

  const editedItemValidation = () => {
    return context.editedItemNewValue
      && context.editedItemNewValue.length >= 3
      && context.editedItem
      && (context.editedItemNewValue.trim().toLowerCase() !== context.editedItem.trim().toLowerCase())
  }

  const handleSubmitEdit = () => {
    if( editedItemValidation() && context.editable && context.editedItem && context.editedItemNewValue ){
      context.onEdit(context.editedItem, context.editedItemNewValue)
      context.setEditedItem('')
      context.setEditableMode(false)
    }
  }

  return (
    <div
      className={classNames({
        'dropdown-footer' : true,
        'edit-mode-footer' : true
      })}
    >
      <button
        className={'cancel-button'}
        type={'button'}
        onClick={handleCancelEditMode}
      >
        anuluj
      </button>
      <button
        className={'submit-edit-button'}
        type={'button'}
        onClick={handleSubmitEdit}
        disabled={!canEditItem()}
      >
        Zapisz
      </button>
    </div>
  )

}

export default EditModeFooter
